import React from 'react'
import { Fade } from 'react-reveal'
import {
  Box,
  Container,
  Flex,
  Link as A,
  Text,
  Icon,
  Avatar,
  Badge,
  Heading
} from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  CareerSectionLead,
  CareerBackground,
  FaqHeader,
  CareerRow,
  CareerBase,
  CareerDiv,
  contentContainer,
  content
} from 'components/Content'
import team from '../assets/imgs/team.jpg'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

A.link = A.withComponent(Link)

const title = 'Careers at- Coding Sastra'

const Bio = ({
  id,
  img,
  title,
  companyName,
  experience,
  location,
  technology,
  desc,
  salary,
  date,
  contactInfo,
  ...props
}) => (
  <CareerDiv mb={0} {...props} key={id}>
    <Box mr={[2, 3]}>
      <Avatar size="60px" src={img} alt={title} />
    </Box>
    <Box>
      <Flex align="center" wrap style={{ lineHeight: '1.125' }}>
        <Heading.h3 fontSize={3} m={0} mr={2} color="black" children={title} />
        <Badge px={2} fontSize={1} bg="primary" children={companyName} />
      </Flex>
      <Box mt={2}>
        <Flex>
          <Icon size={30} glyph="briefcase" color="black" />
          <Text
            fontSize={2}
            mt={1}
            mb={0}
            ml={1}
            color="slate"
            children={experience}
          />
          <Box ml={4}>
            <Flex>
              <Icon size={30} glyph="crosshairs" color="black" />
              <Text
                fontSize={2}
                mt={1}
                mb={0}
                ml={1}
                color="slate"
                children={location}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex>
          <Icon size={30} glyph="code" color="black" />
          <Text
            fontSize={1}
            mt={1}
            mb={0}
            ml={1}
            color="slate"
            children={technology}
          />
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex>
          <Icon size={30} glyph="docs" color="black" />
          <Text
            fontSize={1}
            mt={1}
            mb={0}
            ml={1}
            color="slate"
            children={desc}
          />
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex>
          <Icon size={25} glyph="card" color="black" />
          <Text
            fontSize={1}
            mt={1}
            mb={0}
            ml={1}
            color="slate"
            children={salary}
          />
          <Box ml={7}>
            <Flex>
              <Icon size={20} glyph="notification" color="black" />
              <Text fontSize={1} mb={0} ml={1} color="slate" children={date} />
            </Flex>
          </Box>
        </Flex>
        <Flex mt={2}>
          <Text color="slate" ml={3}>
            How To Apply:
          </Text>
          <Text fontSize={1} ml={2} color="slate" children={contactInfo}></Text>
        </Flex>
      </Box>
    </Box>
  </CareerDiv>
)

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/careers/"
    img="/cards/workshops.png"
  >
    <Nav />
    <Social />
    <CareerBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[2, 2]} mb={[2, 2]} mx="auto">
            <Fade bottom>Placement Assistance & Careers</Fade>
          </Headline>
        </Container>
      </Flex>
    </CareerBackground>
    <Container {...contentContainer}>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>Placement Assistance</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Finding jobs and getting placed is very crucial part of our careers, be
        it for career making as a fresher or for career betterment as an
        experienced. We at Coding Sastra don’t just offer professional online
        training services but also professional placement services and
        assistance for all our current students and our alumni. We help our
        students get a chance to prove themselves and support them throughout
        the journey.Our placement assistance helps students in landing their
        career with different IT firms with technical and project based real
        time training with experts.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>Careers</strong>
        </Text>
      </CareerRow>
      <CareerBase px={3} py={[2, 3]}>
        {data.allContentfulJob.nodes.map(job => {
          return (
            <Bio
              key={job.id}
              id={job.id}
              img={team}
              title={job.title}
              companyName={job.companyName}
              experience={job.experience}
              location={job.location}
              technology={job.skills.skills}
              desc={`Strong in ${job.skills.skills}`}
              salary={job.compensation}
              date="2 days ago"
              contactInfo={job.howToApply.howToApply}
            />
          )
        })}
      </CareerBase>
    </Container>
    <Footer />
  </Layout>
)

export const query = graphql`
  query allJobs {
    allContentfulJob {
      nodes {
        id
        title
        skills {
          skills
        }
        companyName
        compensation
        experience
        location
        howToApply {
          howToApply
        }
      }
    }
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
